import { graphql } from "gatsby"
import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentComponent from "../components/ContentComponent/ContentComponent"
import GuidesPageModule from "../components/GuidesPageModule/GuidesPageModule"
import LandingStaticCard from "../components/LandingStaticCard/LandingStaticCard"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import { Container } from "react-bootstrap"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import { formTracking } from "../components/Common/utils"
import { Helmet } from "react-helmet"


const PrimaryLandingTemplate = ({ data }) => {
  const PageData = data?.strapiPage
  const [shareIcons, setShareIcons] = useState(false)


  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  let popUpData=""

  if(PageData?.add_page_modules?.length>0){
     const results=PageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  var ldJson = {
    "@context": "http://schema.org",
    "@type": "RealEstateAgent",
    Name: "haus & haus",
    URL: pageurl,
    Image: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/haus_homepage_contact_headoffice_e092721ac2_9585806d12.png",
    Logo: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/logo_white_schema_9fd27d4e15.png"
  }


  return (
    <Layout popUpData={popUpData} dark={true} popularSearch={PageData?.select_popular_search?.title}>
      {PageData?.title==="About"&&
      <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(ldJson, null, 2)}
        </script>
      </Helmet>
    }
      <div className="layout-padding-top"></div>
      
      <Container>
      <div className="breadcum-share-wrapper">
       <BreadcrumbModule
          choose_menu={PageData?.choose_menu}
          pagename={PageData?.title}
          type="share-button"
        />

          <div className="share-news-details" onClick={() => openShareicons()}>
            <i className="icon grey-share" />
            <span>Share</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={pageurl}
              />
            )}
          </div>

      </div>
      </Container>
      {PageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" && (
              <ContentComponent data={item} />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_LISTING" && (
              <GuidesPageModule data={item} PageData={PageData} />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS" && (
              <LandingStaticCard data={item} />
            )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage

  return (
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} 
      description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/hh_logo_on_green_background_93feeb7b8b.png"

      />
  )
}

export default PrimaryLandingTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          title
          description {
            data {
              description
            }
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_LISTING {
          __typename
          id
          image_module {
            title
            image {
              url
            }
            link{
              slug
              strapi_parent {
                slug
              }
            }
            link_type
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS {
          __typename
          id
          cards {
            title
            content {
              data {
                content
              }
            }
            cta {
              link {
                slug
                strapi_parent {
                  slug
                }
              }
              title
              custom_link
            }
          }
        }
      }
    }
  }
`
